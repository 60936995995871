import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/API/api.service';
import { CachingService } from 'src/app/services/cache/caching.service';
import { EventService } from 'src/app/services/Events/events.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { WidgetsService } from 'src/app/services/ui/widgets.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  allConfigs: any = [];
  pageCount = 1;
  totalPages = 1;
  searchResult = false;
  constructor(
    private apiService: ApiService,
    private widgetsService: WidgetsService,
    private cachingService: CachingService,
    public globalService: GlobalService,
    private events: EventService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.globalService.activeTab = 'Profile';
    this.getCustomerInfo();
  }

  async getCustomerInfo() {
    if (this.globalService.customerInfo) {
      this.getAllConfigs();
    }
  }

  // async getConfigurations() {
  //   let configs = await this.cachingService.getDataFromSession('user_configurations');
  //   if (configs) {
  //     this.allConfigs = configs;
  //   } else {
  //     this.getAllConfigs();
  //   }
  // }

  //to call configuration api to get all details
  async getAllConfigs() {
    let modelSelected = await this.cachingService.getDataFromSession(
      'modelSelected'
    );
    this.pageCount = 1;
    let requestBody = {
      mobile_number: this.globalService.customerInfo.mobile_number,
      model_id: modelSelected.model_id,
      page: this.pageCount,
    };
    this.widgetsService.showLoader();
    this.apiService.post('configuration/all', requestBody).then(
      async (res: any) => {
        this.widgetsService.hideLoader();
        if (res.message == 'success') {
          this.searchResult = true;
          // store the all config details
          this.totalPages = res.response.pages;

          this.allConfigs = res.response.configurations;
          await this.cachingService.setDataInSession(
            'user_configurations',
            this.allConfigs
          );
        } else if (res.message == 'fail' && typeof res.response == 'object') {
          // store the all config details
          this.allConfigs = res.response.configurations;
          await this.cachingService.setDataInSession(
            'user_configurations',
            this.allConfigs
          );
        } else {
          this.widgetsService.toastWithButtons(
            typeof res.response == 'string'
              ? res.response
              : 'Something went wrong!',
            'danger'
          );
        }
      },
      (error) => {
        this.widgetsService.hideLoader();
        this.widgetsService.toastWithButtons('Something went wrong!', 'danger');
      }
    );
  }
  async loadMore() {
    let modelSelected = await this.cachingService.getDataFromSession(
      'modelSelected'
    );

    this.pageCount =
      this.totalPages == 1 ? (this.pageCount = 1) : this.pageCount + 1;

    let requestBody = {
      mobile_number: this.globalService.customerInfo.mobile_number,
      model_id: modelSelected.model_id,
      page: this.pageCount,
    };
    this.widgetsService.showLoader();
    this.apiService.post('configuration/all', requestBody).then(
      (res: any) => {
        this.widgetsService.hideLoader();
        if (res.message == 'success') {
          this.allConfigs.push(...res.response.configurations);
          this.totalPages = res.response.pages;
        } else {
          this.pageCount = this.pageCount - 1;
        }
      },
      (err) => {
        this.widgetsService.hideLoader();
        this.widgetsService.toastWithButtons(
          this.globalService.apiErrorMsg,
          'danger'
        );
      }
    );
  }

  async createNewConfiguration() {
    let response = await this.cachingService.getDataFromSession('variants');
    await this.cachingService.setDataInSession('oldConfiguration', false);
    await this.cachingService.setDataInSession(
      'oldConfigurationFromSession',
      false
    );
    let selectedVariant = response.variants.filter((variant) => {
      return variant.is_default == 'yes';
    })[0];
    await this.globalService.getVariantDetails(selectedVariant);
    this.events.publish('events', { newConfiguration: selectedVariant });
    this.closeProfilePanel();
    this.apiService.logGAEvent('uaevent', 'Content', 'Click', 'new config');
  }

  async modalToConfirmNewConfiguration() {
    const modal = await this.modalController.create({
      component: ConfirmationModalComponent,
      componentProps: {
        msg: {
          header: 'PROFILE_MSG.HEADER',
          content: 'PROFILE_MSG.CONTENT',
          cancelText: 'PROFILE_MSG.CANCEL',
          confirmText: 'PROFILE_MSG.CONFIRM',
        },
      },
      backdropDismiss: false,
      cssClass: 'confirmation-modal',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    data ? this.createNewConfiguration() : null;
  }

  saveCurrentConfiguration() {
    this.globalService.openBuildNameModal('save');
  }

  async modalToConfirmEditConfiguration(config) {
    const modal = await this.modalController.create({
      component: ConfirmationModalComponent,
      componentProps: {
        msg: {
          header: 'EDIT_CONFIGURATION.HEADER',
          content:
            'EDIT_CONFIGURATION.CONTENT',
          cancelText: 'EDIT_CONFIGURATION.CANCEL',
          confirmText: 'EDIT_CONFIGURATION.CONFIRM',
        },
      },
      backdropDismiss: false,
      cssClass: 'confirmation-modal',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    data ? this.editConfiguration(config) : null;
  }

  async editConfiguration(config) {
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Redirection::Internal',
      'User profile Edit'
    );
    this.apiService.sendReportingData({
      eName: 'click',
      eCat: 'view_profile',
      eAct: 'edit_configuration',
    });
    let response = await this.cachingService.getDataFromSession('variants');
    let pin = await this.cachingService.getDataFromSession('pin_no');
    pin[this.globalService.vehicleCode] = config.pin_no;

    await this.cachingService.setDataInSession('pin_no', pin);
    this.apiService
      .post('configuration/details', { pin_no: config.pin_no })
      .then(async (res: any) => {
        this.apiService.updateReportingData('pinId', config.pin_no);
        await this.cachingService.setDataInSession('oldConfiguration', true);
        let configuration = res.response.configurations[0];
        let variants = response.variants;
        let selectedVariant = variants.filter((variant) => {
          return variant.variant_id == configuration.variant_id;
        })[0];
        //
        this.cachingService.setDataInSession(
          'emi_details',
          configuration.FinanceOptions[0]
        );
        this.cachingService.setDataInSession(
          'exchange_values',
          configuration.ExchangeValues[0]
        );
        let warranty_details = null;
        if (configuration.ExtendedWarrantyInfo[0].VehicleAgeandKmDriven) {
          warranty_details =
            configuration.ExtendedWarrantyInfo[0].VehicleAgeandKmDriven.split(
              ' & '
            );
        }
        let vehicle_age = warranty_details
          ? warranty_details[1].split(' / ')[0]
          : null;
        let mileage = warranty_details
          ? warranty_details[1].split(' / ')[1]
          : null;
        let extendedWarranty_details = {
          kmsDrivenOption: {
            period_code: null,
            product_options:
              configuration.ExtendedWarrantyInfo[0].VehicleAgeandKmDriven?.split(
                ' & '
              )[1],
            sales_period:
              configuration.ExtendedWarrantyInfo[0].VehicleAgeandKmDriven?.split(
                ' & '
              )[0],
            warranty_price:
              configuration.ExtendedWarrantyInfo[0].ExtendedWarrantyPrice,
          },
          warrantyPrice:
            configuration.ExtendedWarrantyInfo[0].ExtendedWarrantyPrice,
          vehicleAge: vehicle_age,
          mileage: mileage,
        };
        this.cachingService.setDataInSession(
          'extendedWarranty_details',
          extendedWarranty_details
        );
        if (configuration.InsuranceOptions[0].insuranceCost) {
          let insurance_details = {
            insuranceCost: configuration.InsuranceOptions[0].insuranceCost
              ? configuration.InsuranceOptions[0].insuranceCost
              : null,
            AddonName: configuration.InsuranceOptions[0].AddonName
              ? configuration.InsuranceOptions[0].AddonName
              : null,
          };
          this.cachingService.setDataInSession(
            'insurancePlan_details',
            insurance_details
          );
        }
        if (
          configuration.AnnualMaintenanceContractInfo[0]
            .AnnualMaintenanceContractPrice
        ) {
          let amc = null;
          if (
            configuration.AnnualMaintenanceContractInfo[0]
              .VehicleAgeandKmDrivenAMC
          ) {
            amc =
              configuration.AnnualMaintenanceContractInfo[0].VehicleAgeandKmDrivenAMC.split(
                ' & '
              );
          }
          let product_year = amc ? amc[1].split(' / ')[0] : null;
          let product_kms = amc ? amc[1].split(' / ')[1] : null;
          let amc_details = {
            product_year: product_year,
            product_kms: product_kms,
            AnnualMaintenanceContractPrice:
              configuration.AnnualMaintenanceContractInfo[0]
                .AnnualMaintenanceContractPrice,
            VehicleAgeandKmDrivenAMC:
              configuration.AnnualMaintenanceContractInfo[0]
                .VehicleAgeandKmDrivenAMC,
          };
          this.cachingService.setDataInSession('amc_details', amc_details);
        }
        //
        this.globalService.variant_color_id = configuration.variant_color_id;
        this.globalService.buildName = configuration.build_name;
        this.globalService.addedAccessories = configuration.accessories;
        this.globalService.addedAccessoriesPacks = configuration.packages;
        this.events.publish('events', {
          loadOldConfiguration: selectedVariant,
        });
        await this.globalService.getVariantDetails(selectedVariant);
        this.closeProfilePanel('edit');
        // this.apiService.logGAEvent('uaevent', 'Content', 'Click', 'old config');
      });
  }

  downloadPDF() {
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Redirection::Internal',
      'User profile PDF download'
    );
    this.apiService.sendReportingData({
      eName: 'click',
      eCat: 'view_profile',
      eAct: 'pdf_downloaded',
    });
  }
  deleteConfiguration(config) {
    // not integrated, remove delete icon for now after confirmation
  }

  async logout() {
    this.cachingService.removeDataFromLocal('userDetails');
    this.cachingService.removeDataFromLocal('visitor_id');
    this.cachingService.removeDataFromSession('pin_no');
    this.globalService.configuration('create');
    this.cachingService.removeDataFromSession('insurancePlan_details');
    this.cachingService.removeDataFromSession('extendedWarranty_details');
    this.cachingService.removeDataFromSession('emi_details');
    this.cachingService.removeDataFromSession('exchange_values');
    this.cachingService.removeDataFromSession('amc_details');
    this.cachingService.removeDataFromSession('buildName');
    this.globalService.customerInfo = null;
    // this.events.publish('events', { viewProfile: false });
    this.modalController.dismiss();
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Redirection::Internal',
      'User profile logout'
    );
    this.apiService.sendReportingData({
      eName: 'click',
      eCat: 'view_profile',
      eAct: 'logout_clicked',
    });
  }

  closeProfilePanel(isEdit?) {
    this.modalController.dismiss(isEdit ? 'edit' : null);
    this.globalService.activeTab = 'accessories';
    this.events.publish('events', { viewProfile: false });
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Redirection::Internal',
      'User profile close'
    );
  }
}
