import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";

/* disable errors originating from injected scripts such as Google Tag Manager */
function isGtagError(event: Sentry.Event | undefined): boolean {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames;

  if (!frames || frames.length === 0) return false;

  if (frames.some((frame) => typeof frame.filename === 'string' && (frame.filename.startsWith('https://www.googletagmanager.com') || frame.filename.startsWith('https://googleads.')))) {
    return true;
  }

  return false;
}

// Function to check if an error is a CORS-related error
const isCorsError = (message: string | undefined): boolean => {
  if (!message) return false;

  return (message.includes("has been blocked by CORS policy") || message.includes("No 'Access-Control-Allow-Origin' header is present") || message.includes("CORS error"));
}

if (environment.isSentryActive) {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.sentryEnvironment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.replayCanvasIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ["error"] })
    ],
    sampleRate: environment.sentrySampleRate, //  Capture n% of the errors
    // tracesSampleRate: environment.sentryTracesSampleRate, //  Capture n% of the transactions
    tracePropagationTargets: environment.sentryDomains,
    denyUrls: environment.sentryDenyUrls,
    allowUrls: environment.sentryDomains,
    maxBreadcrumbs: environment.sentryMaxBreadcrumbs,
    maxValueLength: environment.sentryMaxValueLength,
    beforeSend(event, hint) {
      const error = hint.originalException;

      if (isGtagError(event)) {
        return null; // Ignore GTM errors
      }

      // Ignore specific errors
      const ignoredErrors = environment.sentryIgnoredErrors;
      if (error instanceof Error && ignoredErrors.includes(error.message.toLowerCase())) {
        return null;
      }

      return event;
    },
  });

  if (environment.isAcceptCorsErrors) {
    // Capture only CORS errors from fetch requests
    const originalFetch = window.fetch;
    window.fetch = async (...args) => {
      try {
        const response = await originalFetch(...args);
        if (!response.ok && response.type === "opaque") {
          Sentry.captureException(new Error(`CORS Error: ${response.url}`));
        }
        return response;
      } catch (error) {
        if (error instanceof TypeError && isCorsError(error.message)) {
          Sentry.captureException(error);
        }
        throw error;
      }
    };

    // Capture only CORS errors from XMLHttpRequest
    const originalXHROpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function (...args) {
      this.addEventListener("error", function () {
        Sentry.captureException(new Error(`CORS Error: ${args[1]}`));
      });
      originalXHROpen.apply(this, args);
    };
  }
}

if (environment.disableLogging) {
  function domContentLoaded() {
    'use strict';

    setTimeout(
      console.log.bind(
        console,
        '\n%cStop!',
        'color:red;font-size:50px;font-weight:bold;text-shadow: 1px 1px 0px black, 1px -1px 0px black, -1px 1px 0px black, -1px -1px 0px black;'
      )
    );
    setTimeout(
      console.log.bind(
        console,
        'This is a browser feature intended for developers. If someone told you to copy and paste something here, it is a scam and will give them access to your %caccount',
        'color:red;',
        "etc. so avoid to do that if you are not a developer and doesn't have an understanding of what you are copy and pasting here."
      )
    );
    window.console.log = function () { };
    window.console.error = function () { };
    window.console.info = function () { };
    window.console.warn = function () { };
  }
  document.addEventListener('DOMContentLoaded', domContentLoaded);
  enableProdMode();

  // Block inspect element
  document.onkeydown = function (e) {
    if (e.keyCode == 123) {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
      return false;
    }
    if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
      return false;
    }
  };
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
