export const sentryConfig = {
    isSentryActive: true,
    sentryDsn: "https://ab6e1440c5b13792dfde2771e30e1a74@o4508793334857728.ingest.us.sentry.io/4508793386827776",
    sentryEnvironment: "production",
    sentryDomains: ["https://configurator.jeep.com.au", "https://d1e07brvmnlvvu.cloudfront.net"],
    sentryDenyUrls: [/.*google-analytics.com.*/, /.*facebook.com.*/],
    isAcceptCorsErrors: false,
    sentryMaxBreadcrumbs: 10,
    sentryMaxValueLength: 500,
    sentryIgnoredErrors: ["gtag", "Script error."],
    sentrySampleRate: 1.0,
    sentryTracesSampleRate: 0.8
};