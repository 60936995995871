import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { encode, decode } from 'ee-crypto-encode-decode';

@Injectable({
  providedIn: 'root',
})
export class CachingService {
  encryptData = true;
  constructor() {}

  async setDataInLocal(key: string, data: any) {
    const dataToStore = this.encryptData ? encode(data) : JSON.stringify(data);
    await Preferences.set({ key, value: dataToStore });
  }
  async setDataInSession(key: string, data: any) {
    return new Promise((res, rej) => {
      try {
        const dataToStore = this.encryptData
          ? encode(data)
          : JSON.stringify(data);
        sessionStorage.setItem(key, dataToStore);
        res(true);
      } catch (error) {
        rej(error);
      }
    });
  }

  async getDataFromLocal(key: string) {
    const data = await Preferences.get({ key });
    if (data.value != null && data.value !== '') {
      const decryptedData = this.encryptData
        ? decode(JSON.parse(data.value))
        : JSON.parse(data.value);
      return decryptedData;
    } else {
      return null;
    }
  }

  async getDataFromSession(key: string) {
    const data = await sessionStorage.getItem(key);
    if (data != null && data !== '') {
      const decryptedData = this.encryptData
        ? decode(JSON.parse(data))
        : JSON.parse(data);
      return decryptedData;
    } else {
      return null;
    }
  }

  async removeDataFromLocal(key) {
    await Preferences.remove({ key });
  }
  async removeDataFromSession(key) {
    sessionStorage.removeItem(key);
  }
}
