/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/API/api.service';
import { CachingService } from 'src/app/services/cache/caching.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { WidgetsService } from 'src/app/services/ui/widgets.service';

@Component({
  selector: 'app-save-share-new-popup',
  templateUrl: './save-share-new-popup.component.html',
  styleUrls: ['./save-share-new-popup.component.css'],
})
export class SaveShareNewPopupComponent implements OnInit {
  showEmailSuccessMsg = false;
  showSuccessCopyLink = false;
  enableMail = false; // for now disable mail share

  formErrors = {
    email: '',
  };
  validationMessages = {
    email: {
      required: 'Email ID is required.',
      pattern: 'Please enter valid email address.',
    },
  };
  mailForm: UntypedFormGroup;
  mailFormSubscription: Subscription;
  downloadURL: string;
  disableCta: boolean; // tp disable copy & download cta option if pdf/create api fails

  constructor(
    private modalController: ModalController,
    private formBuilder: UntypedFormBuilder,
    public globalService: GlobalService,
    private apiService: ApiService,
    private widgetsService: WidgetsService,
    private cachingService: CachingService
  ) {}

  ngOnInit() {
    this.setMailForm();
    this.generatePDFUrl();
  }

  ngOnDestroy() {
    this.mailFormSubscription.unsubscribe();
  }

  setMailForm() {
    this.mailForm = this.formBuilder.group({
      email: [
        null,
        {
          validators: [
            Validators.required,
            Validators.pattern(this.globalService.emailRegex),
          ],
        },
      ],
    });
    this.mailFormSubscription = this.mailForm.valueChanges.subscribe(() =>
      this.onValueChanged('mailForm')
    );
    this.onValueChanged('mailForm');
  }

  onValueChanged(formName) {
    if (!this[formName]) {
      return;
    }
    const form = this[formName];
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key];
            }
          }
        }
      }
    }
  }

  async generatePDFUrl() {
    this.widgetsService.showLoader();
    const config_id = await this.cachingService.getDataFromSession('c_id');

    const reqBody = {
      configuration_id: config_id,
    };
    this.apiService.get('pdf/create', reqBody).then(
      (res: any) => {
        if (res.message === 'success') {
          this.downloadURL = res.response.pdf_url;
          const copyLinkInput = document.getElementById('pdf-url-input');
          copyLinkInput.setAttribute('value', this.downloadURL);
          this.disableCta = false;
        } else {
          this.disableCta = true;
          this.widgetsService.toastWithButtons(
            'Something went wrong!',
            'danger'
          );
        }
        this.widgetsService.hideLoader();
      },
      (error) => {
        this.disableCta = true;
        this.widgetsService.hideLoader();
        this.widgetsService.toastWithButtons('Something went wrong!', 'danger');
      }
    );
  }

  async downloadConfiguration() {
    const pinNo = await this.cachingService.getDataFromSession('pin_no');
    const link = document.createElement('a');
    link.href = this.downloadURL;
    link.target = '_blank';
    link.download = `${pinNo[this.globalService.vehicleCode]}.pdf`;
    link.click();
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Save&Share',
      'Download PDF'
    );
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Save&Share::Set_' + this.globalService.idea,
      'Download PDF::Set_' + this.globalService.idea
    );
    this.apiService.visualizerAnalytics('download');
    this.apiService.sendReportingData({
      eName: 'click',
      eCat: 'save_share',
      eAct: 'download_configuration',
    });
  }

  async shareConfiguration() {
    const config_id = await this.cachingService.getDataFromSession('c_id');
    const requestBody = {
      configuration_id: config_id,
      email: this.mailForm.get('email').value,
    };

    this.apiService.get('pdf/share', requestBody).then(
      async (data: any) => {
        this.widgetsService.hideLoader();
        if (data.message === 'success') {
          this.showEmailSuccessMsg = true;
        }
      },
      (error) => {
        this.widgetsService.hideLoader();
        this.widgetsService.toastWithButtons(
          this.globalService.apiErrorMsg,
          'danger'
        );
        console.error('Error : ', error);
      }
    );
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Save&Share',
      'Share on mail'
    );
    this.apiService.sendReportingData({
      eName: 'click',
      eCat: 'save_share',
      eAct: 'share_configuration',
    });
  }

  hideEmailSuccessMsg() {
    if (this.showEmailSuccessMsg) {
      this.showEmailSuccessMsg = false;
    }
  }

  copyURL() {
    navigator.clipboard.writeText(this.downloadURL);
    this.showSuccessCopyLink = true;
    setTimeout(() => {
      this.showSuccessCopyLink = false;
    }, 10000);
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Save&Share',
      'Copy URL'
    );
    this.apiService.visualizerAnalytics('copy_url', this.downloadURL);
  }

  closeModal() {
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Save&Share',
      'close form'
    );
    this.modalController.dismiss();
  }
}
